import React, { useEffect } from 'react';
import { h, render } from 'preact';
import { useCallback } from 'preact/hooks';
import PropTypes from 'prop-types';
import SVGIcon from './svg-icon';

/**
 *
 * @param root0
 * @param root0.player
 */
export default function FullscreenButton( { player, playerContainer, setIsFullscreen } ) {
   
    const toggleFullscreen =  useCallback( () => {
        if (!document.fullscreenElement && !document.webkitFullscreenElement && !document.mozFullScreenElement && !document.msFullscreenElement) {
            // Enter fullscreen mode
            if (playerContainer.current.requestFullscreen) {
                playerContainer.current.requestFullscreen();
            } else if (playerContainer.current.webkitEnterFullscreen) { /* Safari */
                playerContainer.current.webkitEnterFullscreen();
            } else if (playerContainer.current.msRequestFullscreen) { /* IE11 */
                playerContainer.current.msRequestFullscreen();
            }

            player.setViewMode( 'fullscreen' );

            setIsFullscreen( true )
        } else {
            // Exit fullscreen mode
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.webkitExitFullscreen) { /* Safari */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE11 */
                document.msExitFullscreen();
            }

            player.setViewMode( 'inline' );

            setIsFullscreen( false )
        }
    }, [player, playerContainer, setIsFullscreen] );

    useEffect( () => {
          document.addEventListener("fullscreenchange", ( event ) => {
            if (!document.fullscreenElement && !document.webkitCurrentFullScreenElement) {
                setIsFullscreen( false )
            }
          });
    }, [ setIsFullscreen ])


    return (
        <div className="video-player-mk__control">
            <button onClick={ () => toggleFullscreen() } className="video-player-mk__control-button" type="button" title="Fullscreen" aria-disabled="false">
                <SVGIcon name="fullscreen" cssClass="video-player-mk__control-button-icon" />
            </button>
        </div>
    );
}

FullscreenButton.propTypes = {
    player: PropTypes.object.isRequired,
    playerContainer: PropTypes.element.isRequired,
};