import React, { useRef } from 'react';
import { h, render } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import PropTypes from 'prop-types';

function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = secs < 10 ? `0${secs}` : secs;

    return `${formattedHours !== '00' ? `${ formattedHours }:` : ''}${formattedMinutes}:${formattedSeconds}`;
}

/**
 *
 * @param root0
 * @param root0.player
 */
export default function VideoScrubber( { player } ) {
    const [ currentProgress, setCurrentProgress ] = useState( 0 );
    const [ tooltipPosition, setTooltipPosition ] = useState( 0 );
    const [ tooltipString, setTooltipString ] = useState( '00:00' );
    const progressBarContainer = useRef( null );
    const onPlayerTimeChanged = useCallback( () => {
        const playerDuration = player.getDuration(); // this is in seconds
        const playerCurrentTime = player.getCurrentTime('relativetime'); // get relative offsets so that this covers live as well
        const playerProgress = ((playerCurrentTime / playerDuration) * 100).toFixed( 2 );

        setCurrentProgress( playerProgress )
    }, [ player ])

    const scrubberMouseover = ( event ) => {
        if ( event.offsetX > 1 ) {
            setTooltipPosition( event.offsetX );
            const seekOffset = (tooltipPosition / progressBarContainer.current.clientWidth) * player.getDuration();

            setTooltipString( formatTime( seekOffset ) ) 
        }
    };

    const moveToPosition = useCallback( ( event ) => {
        const seekOffset = (tooltipPosition / progressBarContainer.current.clientWidth) * player.getDuration();
        if ( !player.isPlaying() ) {
            player.play();
        } 

        player.seek(seekOffset);
    }, [ player, progressBarContainer, tooltipPosition ] );

    useEffect( () => {
        if ( player ) {
            player.on(mkplayer.MKPlayerEvent.TimeChanged, onPlayerTimeChanged.bind(this));
        }
    }, [onPlayerTimeChanged, player] )

    return (
        <div className="video-player-mk__scrubber" ref={ progressBarContainer } onClick={ moveToPosition } onMouseMove={ scrubberMouseover }>
            <div className="video-player-mk__scrubber-tooltip-container" style={ { left: `${ tooltipPosition }px`}}>
                <div className="video-player-mk__scrubber-tooltip">
                    { tooltipString }
                </div>
            </div>
            <div className="video-player-mk__scrubber-bar" style={ { width: `${ currentProgress }%` } }></div>

        </div>
    );
}

VideoScrubber.propTypes = {
    player: PropTypes.object.isRequired
};