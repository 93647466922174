import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { LiveBlogContainer } from '../components/LiveBlog';

export const LiveBlog = ({ store, data }) => {

    return (
        <Provider store={store}>
            <LiveBlogContainer 
                blogData={ data }
            />
        </Provider>
    );
};

LiveBlog.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired
};
