import React from 'react';
import { h, render } from 'preact';
import { Provider } from 'react-redux';
import VideoMediaKind from '../components/video-media-kind';
import PropTypes from 'prop-types';

export const VideoMediaKindContainer = ( { playVideo, player, setPlayer, videoData } ) => {

    return (
        <VideoMediaKind
            playVideo={ playVideo }
            player={ player }
            setPlayer={ setPlayer }
            videoData={ videoData }
        />
    );
};

VideoMediaKindContainer.propTypes = {
    playVideo: PropTypes.func.isRequired,
    player: PropTypes.object.isRequired,
    setPlayer: PropTypes.func.isRequired,
    videoData: PropTypes.object
};