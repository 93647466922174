import React from 'react';
import { h, render } from 'preact';
import { useCallback, useState, useEffect } from 'preact/hooks';
import PropTypes from 'prop-types';
import SVGIcon from './svg-icon';

/**
 *
 * @param root0
 * @param root0.player
 */
export default function Subtitles( { player } ) {
    const [ subtitleTrack, setSubtitleTrack ] = useState( null );
    const [ subtitlesShowing, setSubtitlesShowing ] = useState( false );

    useEffect( () => {
        if ( player ) {
            player.on(mkplayer.MKPlayerEvent.Ready, () => {
                const subtitlesList = player.subtitles.list();
                subtitlesList.length && subtitlesList[ 0 ].lang !== 'unknown' ? setSubtitleTrack( subtitlesList[ 0 ] ) : setSubtitleTrack( null );
            });
        }
    }, [ player ] )

    const toggleSubtitles =  useCallback( () => {

        if ( player && subtitleTrack ) {
            if ( subtitlesShowing ) {
                player.subtitles.disable( subtitleTrack.id );
                setSubtitlesShowing( false );
            } else if ( subtitleTrack ) {
                player.subtitles.enable( subtitleTrack.id );
                setSubtitlesShowing( true );
            }
        }
    }, [player, subtitleTrack, subtitlesShowing] );

    return (
        subtitleTrack ? (
            <div className="video-player-mk__control">
                <button onClick={ () => toggleSubtitles() } className="video-player-mk__control-button" type="button" title="Subtitles" aria-disabled="false">
                    <SVGIcon name="closed-caption" cssClass="video-player-mk__control-button-icon" />
                </button>
            </div>
        ) : null
        
    );
}

Subtitles.propTypes = {
    player: PropTypes.object.isRequired
};