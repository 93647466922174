import React from 'react';
import { h, render } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import PropTypes from 'prop-types';

/**
 *
 * @param seconds
 */
function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = secs < 10 ? `0${secs}` : secs;

    return `${formattedHours !== '00' ? `${ formattedHours }:` : ''}${formattedMinutes}:${formattedSeconds}`;
}

/**
 *
 * @param root0
 * @param root0.player
 */
export default function CurrentTime( { player } ) {
    const [ currentTime, setCurrentTime ] = useState( '00:00' );
    const [ duration, setDuration ] = useState( '00:00' );

    const onPlayerTimeChanged = useCallback( () => {
        const playerDuration = player.getDuration(); // this is in seconds
        const playerCurrentTime = player.getCurrentTime('relativetime'); // get relative offsets so that this covers live as well
       
        setDuration( formatTime( playerDuration ) )
        setCurrentTime( formatTime( playerCurrentTime ) )
    }, [ player ])

    useEffect( () => {
        if ( player ) {
            player.on(mkplayer.MKPlayerEvent.TimeChanged, onPlayerTimeChanged.bind(this));
        }
    }, [onPlayerTimeChanged, player] )
    
    return (
        <div className="video-player-mk__current-time">
            { currentTime } / { duration }
        </div>              
    );
}

CurrentTime.propTypes = {
    player: PropTypes.object.isRequired,
};