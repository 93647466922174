import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchWithRetryBQ } from './base-queries/fetch-with-retry';

const { app } = PULSE;

/**
 * Checks if query parameters for content API contains legacy `page` and `pageSize` params.
 * See: https://pulselive.atlassian.net/wiki/spaces/CD/pages/4421517356/Replacing+pagination+with+limit+offset+in+content+queries
 *
 * @param {*} params - query parameters object
 */
const checkContentParams = (params) => {
    if (params?.pageSize || params?.page) {
        console.warn(
            `Invalid legacy parameters passed to content API, 'page' | 'pageSize'; use 'limit' and 'offset' instead`
        );
    }
};

// RTK Query update: Define an api service with a base URL and retry attempts
// RTK Query update: Endpoints can be added here or injected in components
export const contentApi = createApi({
    reducerPath: 'contentApi',
    baseQuery: fetchWithRetryBQ(app.environment.api),
    endpoints: (builder) => ({
        // GET /content/{accountName}/{type}/{lang}/{id} - Get Content by type/lang and ID
        getContentItem: builder.query({
            query: ({ type, id, params }) => {
                checkContentParams(params);
                return app.common.createContentPath(type, params, 'en', id);
            }
        }),
        // GET /content/{accountName}/{type}/{lang} - Get Content by type/lang
        getContent: builder.query({
            query: ({ type, params }) => {
                checkContentParams(params);
                return `/content/${
                    app.account
                }/${type}/${'en'}${app.common.prepareParams(params)}`;
            }
        }),
        // GET /content/{accountName}/all/{lang}
        getAllContent: builder.query({
            query: ({ params }) => {
                checkContentParams(params);
                return `/content/${
                    app.account
                }/all/${'en'}${app.common.prepareParams(params)}`;
            }
        }),
        // GET /content/{accountName}/all/{lang}
        getLiveBlog: builder.query({
            query: ({ lang, id, params, belowPosition }) =>
                app.common.createLiveBlogPath(lang, id, params, belowPosition)
        }),
        /**
         * Gets a live blog with entries newer than the given timestamp
         */
        getNewerThan: builder.query({
            query: ({ id, lang, timestamp, params }) => ({
                url: `/liveblog/${app.account}/${id}/${ lang ? lang : 'en' }/newerThan/${timestamp}${app.common.prepareParams(params)}`
            }),
            transformResponse: (response) => {
                return response;
            }
        })
    })
});

export const {
    useGetContentItemQuery,
    useGetContentQuery,
    useGetAllContentQuery,
    useGetLiveBlogQuery,
    useGetNewerThanQuery
} = contentApi;
