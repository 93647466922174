import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { VideoMediaKindContainer } from '../components/VideoMediaKind';
import { useState } from 'preact/hooks';

let listenersSet = false;

export const VideoMediaKind = ({ store, data }) => {
    const [ player, setPlayer ] = useState( null );

    const playVideo = ( sourceConfig ) => {
        console.log("play video")
        player.load(sourceConfig)
        .then(() => {
            setListeners();
        })
        .catch((error) => {
            console.error(error)
        } );
    }

    const unloadPlayer = () => {
        if ( player ) {
            player.unload()
        }
    }

    const setListeners = () => {
        if ( !listenersSet ) {

            player.on( mkplayer.MKPlayerEvent.PlaybackFinished, () => {
                PULSE.app.common.event.dispatch(
                    PULSE.app.common.event.VIDEO.END,
                    {
                        playerId: '',
                        mediaId: player.sourceConfig.mediaId
                    },
                    document.body
                );
            } )
    
            player.on( mkplayer.MKPlayerEvent.Play, () => {
                PULSE.app.common.event.dispatch(
                    PULSE.app.common.event.VIDEO.PLAY,
                    {
                        playerId: '',
                        mediaId: player.sourceConfig.mediaId
                    },
                    document.body
                );
            } )
    
            player.on( mkplayer.MKPlayerEvent.Paused, () => {
                PULSE.app.common.event.dispatch(
                    PULSE.app.common.event.VIDEO.PAUSE,
                    {
                        playerId: '',
                        mediaId: player.sourceConfig.mediaId
                    },
                    document.body
                );
            } )

            document.body.addEventListener(
                PULSE.app.common.event.PLAYLIST.UNLOAD_MEDIA_KIND_PLAYER, ( event ) => {
                    unloadPlayer();
                }
            );
            
            listenersSet = true;
        }
    }

    return (
        <Provider store={store}>
            <VideoMediaKindContainer 
                playVideo={ playVideo }
                player={ player }
                setPlayer={ setPlayer }
                videoData={ data }
            />
        </Provider>
    );
};

VideoMediaKind.propTypes = {
    store: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired
};
