import React from 'react';
import { h, render } from 'preact';
import { useCallback, useEffect, useState, useRef } from 'preact/hooks';
import PropTypes from 'prop-types';
import SVGIcon from './svg-icon';

/**
 *
 * @param root0
 * @param root0.player
 */
export default function VolumeButton( { player } ) {
    const [ isMuted, setIsMuted ] = useState( false );
    const [ currentVolume, setCurrentVolume ] = useState( 100 );
    const [ prevVolume, setPrevVolume ] = useState( 100 );
    const volumeRange = useRef( null );

    const toggleMute = useCallback( () => {
        if (player) {
            setIsMuted( player.isMuted() ? false : true )
            player.isMuted() ? setCurrentVolume( prevVolume ) : setCurrentVolume( 0 );
            player.isMuted() ? player.unmute() : player.mute();
        }
    }, [player, prevVolume] );

    const adjustVolume = useCallback( () => {
        if (player) {
            setCurrentVolume( volumeRange.current.value );
            setPrevVolume( volumeRange.current.value );
            player.setVolume(volumeRange.current.value);

            if ( volumeRange.current.value === '0' ) {
                toggleMute();
                setIsMuted( true )
            } else {
                player.unmute();
                setIsMuted( false )
            }
        }
    }, [ player, volumeRange ] );
    
    return (
        <div className="video-player-mk__control video-player-mk__control--volume">
            <button onClick={ toggleMute } className="video-player-mk__control-button" type="button" title="Change Volume" aria-disabled="false">
                {
                    isMuted ? (
                        <SVGIcon name="volume-mute" cssClass="video-player-mk__control-button-icon" />
                    ) : (
                        <SVGIcon name="volume-up" cssClass="video-player-mk__control-button-icon" />
                    )
                }
            </button>

            <input className="video-player-mk__volume-slider" ref={ volumeRange } onChange={ adjustVolume } type="range" min="0" max="100" step="1" value={ currentVolume } />
        </div>
    );
}

VolumeButton.propTypes = {
    player: PropTypes.object.isRequired
};