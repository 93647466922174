import React from 'react';
import { h, render } from 'preact';
import { Provider } from 'react-redux';
import HelloWorld from '../components/hello-world';

export const TestReactContainer = () => {

    return (
        <HelloWorld />
    );
};
