import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux'
import { contentApi } from './content-api';
import { plApi } from './pl-api';

// RTK Query update: Add required exports
if (window.PULSE.app.redux) {
    window.PULSE.app.redux.configureStore = configureStore;
    window.PULSE.app.redux.createListenerMiddleware = createListenerMiddleware;
    window.PULSE.app.redux.contentApi = contentApi;
    window.PULSE.app.redux.plApi = plApi;
    window.PULSE.app.redux.combineReducers = combineReducers;
} else {
    window.PULSE.app.redux = {
        reducers: {},
        sagas: {},
        configureStore: configureStore,
        createListenerMiddleware: createListenerMiddleware,
        combineReducers: combineReducers,
        contentApi: contentApi,
        plApi: plApi,
    };
}

window.PULSE.app.reduxStore();
