import React from 'react';
import { h, render } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import PropTypes from 'prop-types';
import SVGIcon from './svg-icon';

/**
 *
 * @param root0
 * @param root0.player
 */
export default function PlayButton( { player } ) {
    const [ playingState, setPlayingState ] = useState( 'playing' );
   
    const togglePlayPause =  useCallback( () => {
        if (player) {
            player.isPlaying() ? player.pause() : player.play();
        }
    }, [ player ] );

    useEffect( () => {
        if ( player ) {
            player.on(mkplayer.MKPlayerEvent.Playing, () => {
                setPlayingState( 'playing' )
            });

            player.on(mkplayer.MKPlayerEvent.Paused, () => {
                setPlayingState( 'paused' )
            });

            player.on(mkplayer.MKPlayerEvent.PlaybackFinished, () => {
                setPlayingState( 'finished' )
            });
        }
    }, [ player ] )

    return (
        <div className="video-player-mk__control">
            <button onClick={ () => togglePlayPause() } className="video-player-mk__control-button" type="button" title="Play" aria-disabled="false">
                {
                    playingState === 'playing' ? (
                        <SVGIcon name="pause" cssClass="video-player-mk__control-button-icon" />
                    ) : playingState === 'paused' ? (
                        <SVGIcon name="play" cssClass="video-player-mk__control-button-icon" />
                    ) : (
                        <SVGIcon name="play" cssClass="video-player-mk__control-button-icon" />
                    )
                }
            </button>
        </div>
    );
}

PlayButton.propTypes = {
    player: PropTypes.object.isRequired
};