import React, { useRef } from 'react';
import { h, render } from 'preact';

/**
 *
 * @param root0
 * @param root0.url
 * @param root0.title
 * @param root0.cssClass
 * @param root0.lazyload
 * @param root0.objectFit
 */
export default function ImageElement( { url, title = '', cssClass = '', lazyload = 'lazy', objectFit = false } ) {

    return (
        <img src={ url } alt={ title }
            className={ `img ${ cssClass } ${ objectFit ? 'object-fit-cover-picture__img' : '' }` } loading={ lazyload } />
    );
}
