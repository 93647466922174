import React from 'react';
import { h } from 'preact';

/**
 *
 * @param root0
 * @param root0.name
 * @param root0.cssClass
 * @param root0.title
 */
export default function SVGIcon( { name, cssClass = '', title = '' } ) {
    if ( name ) {
        return (
            <svg className={ `icon ${ cssClass }` } aria-hidden="true" role="img">
                { title ? <title>{ title }</title> : '' }
                <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={ `${window.BASE_RESOURCE_URL}/${window.RESOURCE_VERSION}/i/svg-output/icons.svg#icn-${name}` }></use>
            </svg>
        );
    }
}