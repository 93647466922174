import React, { useRef } from 'react';
import { h, render } from 'preact';
import { useCallback, useEffect, useState, useMemo } from 'preact/hooks';
import PropTypes from 'prop-types';
import SVGIcon from 'common/components/svg-icon.jsx';

function checkString(str) {
    // Regular expression to match a string that starts with 't' (case-insensitive) and has a number after
    const regex = /^t.*\d/i;
    
    // Test the string against the regular expression
    return regex.test(str);
}

/**
 *
 * @param root0
 * @param root0.player
 * @param root0.keyEvent
 * @param root0.liveBlogData
 */
export default function KeyEvent( { keyEvent, liveBlogData, blogPosts, setKeyEventPostData } ) {
    const keyEventEntry = blogPosts?.find( entry => entry.id === keyEvent.entryId ) || keyEvent;
    const keyEventType = keyEventEntry?.subtitle || '';

    const eventIsContent = useMemo( () => {

        if ( keyEventType === 'text' || keyEventType === 'video' ) {
            return true;
        }

        return false;
    }, [ keyEventType ])

    const onViewPostClick = useCallback( () => {
        setKeyEventPostData( keyEventEntry )
    }, [keyEventEntry, setKeyEventPostData] )

    const keyEventIcon = keyEventEntry?.icon?.html || '';

    const isAnchored = useMemo(() => {
        return keyEventEntry?.properties?.additionalInfo === 'anchored';
    }, [keyEventEntry]) 

    let clubBadge = ''
    let clubBadgeRetina = ''

    if ( keyEventEntry ) {
        // If an opta ID is given, update the entry model
        if (keyEventEntry?.subtitle && checkString( keyEventEntry.subtitle )) {
            clubBadge = PULSE.app.common.getClubBadge(
                keyEventEntry.subtitle,
                '50'
            );
            clubBadgeRetina = PULSE.app.common.getClubBadge(
                keyEventEntry.subtitle,
                '50'
            );
        }
    }

    const hasClubBadge = clubBadge !== '';

    const eventIsBulletPoint = useMemo( () => {
        return !hasClubBadge ? (keyEventIcon ? false : true) : false;
    }, [ keyEvent ])

    return (
        keyEventEntry ? (
            <div className="live-blog__header-key-event">
                <div className="live-blog__header-key-event-text-container">
                    {
                        hasClubBadge ? (
                            <span className="badge badge-image-container" data-widget="club-badge-image" data-size="50">
                                <p>{ hasClubBadge }</p>
                                <img className="badge-image badge-image--20 js-badge-image" src={ clubBadge } srcSet={ `${ clubBadge }, ${ clubBadgeRetina } 2x` } />
                            </span>
                        ) : keyEventIcon ? (
                            <img className="live-blog__header-key-event-text-icon" src={ `https://resources.premierleague.com/premierleague/blog-icons/${ keyEventIcon }-white.png` } />
                        ) : (
                            <span className="live-blog__header-key-event-text-icon live-blog__header-key-event-text-icon--no-icon"></span>
                        )
                    }
                    {
                        keyEventEntry.titleHtml ? (
                            <h5 className="live-blog__header-key-event-text" dangerouslySetInnerHTML={ { __html: keyEventEntry.titleHtml } }></h5>
                        ) : (
                            <h5 className="live-blog__header-key-event-text">{ keyEventEntry.title }</h5>
                        )
                    }
                </div>

                {
                    (!eventIsBulletPoint && eventIsContent) ? (
                       <a className="live-blog__header-key-event-link" target="_blank" href={ keyEventEntry?.properties?.additionalInfo || '' } rel="noreferrer">
                            { keyEventType === 'video' ? 'Watch Now' : 'View here' }
                            <SVGIcon name="external-no-fill" cssClass="live-blog__header-key-event-icon" />
                       </a>
                    ) : (isAnchored || (!eventIsBulletPoint && !eventIsContent)) ? (
                        <p className="live-blog__header-key-event-link" onClick={ () => onViewPostClick( keyEventEntry.id ) }>
                            View Post
                            <SVGIcon name="arrow-down" cssClass="live-blog__header-key-event-icon" />
                        </p>
                    ) : (
                        null
                    )
                }
            </div>
        ) : null
    );
}

KeyEvent.propTypes = {
    keyEvent: PropTypes.object.isRequired,
    liveBlogData: PropTypes.object.isRequired,
    blogPosts: PropTypes.array.isRequired
};