import { createApi } from '@reduxjs/toolkit/query/react';

import { accountHeaderWithRetryBQ } from './base-queries/account-header-with-retry';

const { app } = PULSE;

// RTK Query update: Define an api service with a base URL and retry attempts
// RTK Query update: Endpoints can be added here or injected in components
export const plApi = createApi({
    reducerPath: 'plApi',
    baseQuery: accountHeaderWithRetryBQ(app.environment.api),
    endpoints: (build) => ({
        getCompetitions: build.query({
            query: () => 'football/competitions?pageSize=50',
            transformResponse: (response) => response?.competitions
        }),
        getCompSeason: build.query({
            query: ({ id }) => `football/compseasons/${id}`,
            transformResponse: (response) => response?.compSeasons
        }),
        getTeams: build.query({
            query: (compSeasonId) =>
                `football/teams?compSeasonId=${compSeasonId}&pageSize=100`,
            transformResponse: (response) => response?.teams
        }),
        getMatch: build.query({
            query: ({ id, params }) => `football/fixtures/${ id }${app.common.prepareParams(params)}`
        }),
        getMatches: build.query({
            query: (params) => `football/fixtures${app.common.prepareParams(params)}`,
            transformResponse: (response) => response?.content
        })
    })
});

export const {
    useGetCompetitionsQuery,
    useGetTeamsQuery,
    useGetMatchesQuery,
    useGetMatchQuery,
    useGetCompSeasonQuery
} = plApi;
