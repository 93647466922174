import React from 'react';
import PropTypes from 'prop-types';
import BlogEntry from './blog-entry';

/**
 *
 * @param root0
 * @param root0.player
 */
export default function LiveBlogEntries({
    liveBlogData,
    blogPostsToDisplay,
    blogPosts,
    keyEventPostData,
    isFetching
}) {
    const pinnedPost = blogPosts.find((entry) =>
        entry?.subtitle?.includes('pinned')
    );
    const sortedPosts = blogPostsToDisplay.sort(
        (a, b) => b.position - a.position
    );

    return (
        <div className="live-blog__entries-container">
            <BlogEntry blogEntryData={pinnedPost} isPinned={true} />

            <BlogEntry blogEntryData={keyEventPostData} />

            {keyEventPostData || pinnedPost ? (
                <div className="live-blog__key-events-divider">
                    <span>Live updates continue below</span>
                </div>
            ) : null}

            {sortedPosts.map((liveBlogEntry, index) => (
                <BlogEntry key={index} blogEntryData={liveBlogEntry} />
            ))}
            {isFetching && (
                <div class="live-blog__loader-container">
                    <div className="loader-small">
                        <div className="line"></div>
                    </div>
                </div>
            )}
        </div>
    );
}

LiveBlogEntries.propTypes = {
    liveBlogData: PropTypes.object.isRequired,
    blogPostsToDisplay: PropTypes.array.isRequired,
    blogPosts: PropTypes.array.isRequired,
    keyEventPostData: PropTypes.object.isRequired
};
