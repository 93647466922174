import React from 'react';
import { h, render } from 'preact';
import { useCallback } from 'preact/hooks';
import PropTypes from 'prop-types';
import SVGIcon from './svg-icon';

/**
 *
 * @param root0
 * @param root0.player
 */
export default function PictureInPicture( { player, playerContainer } ) {

    const togglePicInPic =  useCallback( () => {
        if (player) {

            const videoElement = playerContainer.current.querySelector('video');

            try {
                if (videoElement !== document.pictureInPictureElement) {
                    videoElement.requestPictureInPicture();
                } else {
                    document.exitPictureInPicture();
                }
            } catch (error) {
                console.log(error);
            }
        }
    }, [player, playerContainer] );

    return (
        <div className="video-player-mk__control video-player-mk__control--picture-in-picture">
            <button onClick={ () => togglePicInPic() } className="video-player-mk__control-button" type="button" title="Picture in Picture" aria-disabled="false">
                <SVGIcon name="picture-in-picture" cssClass="video-player-mk__control-button-icon" />
            </button>
        </div>
    );
}

PictureInPicture.propTypes = {
    player: PropTypes.object.isRequired
};