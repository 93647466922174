import React, { Fragment, useMemo, useRef } from 'react';
import { h, render } from 'preact';
import PropTypes from 'prop-types';
import KeyEvent from './key-event';
import DayPicker from './day-picker';
import ImageElement from 'common/components/lazy-image/image-element.jsx';
import { getTranslation } from 'common/react/utils/translations';
import SVGIcon from 'common/components/svg-icon.jsx';

/**
 * LiveBlogHeader
 *
 * This renders the live blog header, using the live blog data from live-blog.jsx
 *
 * @param {string} blogData - The DOMElement data attributes
 * @param {object} liveBlogData - Data from the live blog api request
 * @param {object} blogOverview - Blog Header/overview from the initial live blog api request
 * @param {object} blogPosts - All blog posts from the api request
 * @param {func} setKeyEventPostData - useState function to set which post has been clicked for key events
 * @param {boolean} hasMultipleDays - Boolean if the blog has multiple days and the day picker should be displayed
 * @param {array} blogDaysArray - array of the days of the blog
 * @param {string} blogId - blog id of the current blog
 * @param {func} setBlogId - useState function to update the current live blog when user changes blog day
 * @param {func} resetBlog - function to reset the live blog when a user changes the day using the day picker
 *
 * @returns {JSX.Element} - Live Blog header widget
 */
export default function LiveBlogHeader({
    liveBlogData,
    blogOverview,
    blogPosts,
    setKeyEventPostData,
    hasMultipleDays,
    blogDaysArray,
    blogId,
    setBlogId,
    resetBlog,
    commentEmail,
    defaultSubject,
    isFetching,
    maxKeyEvents,
    author,
    isLive
}) {
    const keyEvents = []
        .concat(blogOverview?.keyEvents)
        .reverse()
        .slice(0, maxKeyEvents);

    const blogTitleArray = useMemo(() => {
        return blogOverview?.title ? blogOverview?.title.split('~') : '';
    }, [blogOverview]);

    const headlineEvents = useMemo(() => {
        const headerEvents = blogOverview?.headerEvents;
        if (keyEvents.length === maxKeyEvents) {
            return;
        }
        if (keyEvents?.length && keyEvents[0]) {
            return headerEvents?.length
                ? headerEvents.slice(0, maxKeyEvents - keyEvents.length)
                : null;
        } else if (headerEvents?.length) {
            return headerEvents.slice(0, maxKeyEvents);
        }
    }, [liveBlogData, keyEvents]);

    const isError = useMemo(() => {
        if (!isFetching && !liveBlogData) {
            return true;
        }
        return false;
    }, [isFetching, liveBlogData]);

    return (
        <div className="live-blog__header">
            {blogOverview ? (
                <>
                    <h2 className="live-blog__header-title u-show-tab">
                        {isLive && (
                            <span className="live-blog__header-title--live">
                                {getTranslation('label.live')} &nbsp;
                            </span>
                        )}
                        {blogTitleArray.map((title, index) => {
                            return (
                                <>
                                    <span
                                        className={
                                            blogTitleArray.length > 1 &&
                                            index === 0
                                                ? 'live-blog__header-title--live'
                                                : ''
                                        }
                                        key={index}
                                    >
                                        {title}{' '}
                                    </span>
                                </>
                            );
                        })}
                    </h2>
                    {author && (
                        <span className="live-blog__header-author u-show-tab">
                            {getTranslation('label.by')} {author}
                        </span>
                    )}
                </>
            ) : null}

            <div className="live-blog__header-container">
                {blogOverview ? (
                    <Fragment>
                        <div className="live-blog__header-content">
                            <h2 className="live-blog__header-title u-hide-tab">
                                {isLive && (
                                    <span className="live-blog__header-title--live">
                                        {getTranslation('label.live')} &nbsp;
                                    </span>
                                )}
                                {blogTitleArray.map((title, index) => {
                                    return (
                                        <span
                                            className={
                                                blogTitleArray.length > 1 &&
                                                index === 0
                                                    ? 'live-blog__header-title--live'
                                                    : ''
                                            }
                                            key={index}
                                        >
                                            {title}{' '}
                                        </span>
                                    );
                                })}
                            </h2>
                            {author && (
                                <span className="live-blog__header-author u-hide-tab">
                                    {getTranslation('label.by')} {author}
                                </span>
                            )}

                            {keyEvents
                                ? keyEvents
                                      .slice(0, 6)
                                      .map((keyEvent, index) => {
                                          return !keyEvent?.subtitle?.includes(
                                              'pinned'
                                          ) ? (
                                              <KeyEvent
                                                  key={index}
                                                  keyEvent={keyEvent}
                                                  liveBlogData={liveBlogData}
                                                  blogPosts={blogPosts}
                                                  setKeyEventPostData={
                                                      setKeyEventPostData
                                                  }
                                              />
                                          ) : null;
                                      })
                                : null}

                            {headlineEvents
                                ? headlineEvents.map((headlineEvent, index) => {
                                      return (
                                          <KeyEvent
                                              key={index}
                                              keyEvent={headlineEvent}
                                          />
                                      );
                                  })
                                : null}
                        </div>
                        <div className="live-blog__header-image-container">
                            {hasMultipleDays && (
                                <DayPicker
                                    liveBlogData={liveBlogData}
                                    hasMultipleDays={hasMultipleDays}
                                    blogDaysArray={blogDaysArray}
                                    blogId={blogId}
                                    setBlogId={setBlogId}
                                    resetBlog={resetBlog}
                                />
                            )}
                            <ImageElement
                                url={PULSE.app.common.getImageUrl(
                                    blogOverview.leadMedia,
                                    '500'
                                )}
                                cssClass="live-blog__header-image"
                            />
                        </div>
                    </Fragment>
                ) : null}

                {isFetching && (
                    <div className="loader-small">
                        <div className="line"></div>
                    </div>
                )}

                {isError && (
                    <div className="live-blog__error-message">
                        {getTranslation('label.liveblog.noblogs')}
                    </div>
                )}
            </div>
            {commentEmail && (
                <div className="live-blog__comment-container">
                    <div className="live-blog__comment-text">
                        {getTranslation('label.liveblog.comment.description')}
                    </div>
                    <a
                        className="live-blog__comment-button global-btn"
                        href={`mailto:${commentEmail}?subject=${encodeURI(
                            defaultSubject
                        )}`}
                        aria-label="Live Blog comment button"
                    >
                        {getTranslation('label.comment')}
                        <SVGIcon
                            name="arrow-right"
                            cssClass="live-blog__comment-button-icon"
                        />
                    </a>
                </div>
            )}
        </div>
    );
}

LiveBlogHeader.propTypes = {
    liveBlogData: PropTypes.object.isRequired,
    blogOverview: PropTypes.object.isRequired,
    blogPosts: PropTypes.object.isRequired,
    setKeyEventPostData: PropTypes.func.isRequired,
    hasMultipleDays: PropTypes.bool.isRequired,
    blogDaysArray: PropTypes.array.isRequired,
    blogId: PropTypes.string.isRequired,
    setBlogId: PropTypes.func.isRequired,
    resetBlog: PropTypes.func.isRequired
};
