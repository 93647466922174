import React from 'react';
import { h, render } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { useGetContentQuery } from 'common/store/modules/api/content-api';
import { useGetMatchesQuery } from '../../../../../../common/store/modules/api/pl-api';
import { useGetMatchQuery } from '../../../../../../common/store/modules/api/pl-api';

const SHORT_POLLING_TIME = 5000;

export default function HelloWorld( {} ) {
    const [ currentString, setCurrentString ] = useState( '' );
    const [ pollingInterval, setPollingInterval ] = useState( SHORT_POLLING_TIME );
    const { data: requestData = [], isFetching } =
        useGetContentQuery({type: 'text'});
    const newsData = requestData?.content;

    const { data: matchesData, isFetchingMatches } =
        useGetMatchesQuery({
            comps: 1,
            compSeasons: 664,
            teams: "1,2,127,130,131,43,4,6,7,34,10,163,11,12,23,15,18,21,25,38",
            page: 0,
            pageSize: 20,
            sort: 'desc',
            statuses: "A,C",
            altIds: true,
            detail: 2,
            fast: true
        });

        const { data: singleMatchData, isFetchingSingleMatches } =
        useGetMatchQuery( { id: '81486', params: {} },
        {
            pollingInterval
        });

    // Listen for the parent to say when a user is hovering a card
    useEffect( () => {
        if ( newsData && newsData.length ) {
            setCurrentString( newsData[ 0 ]?.title || 'No News Available' )
        }
    }, [ newsData ] );

    return (
        <div>
            <h1>Current Article: { currentString }</h1>
            {isFetching ? (
                <h3>Loading News Articles</h3>
            ) : !newsData || !newsData.length ? (
                <h3>No News</h3>
            ) : (
                <div>
                    {newsData.map((newsItem) => {
                        return (
                            <h3 onClick={ () => setCurrentString(newsItem.title) }>{ newsItem.title }</h3>
                        );
                    })}
                </div>
            )}

            <h1>Single Match:</h1>
            { isFetchingSingleMatches ? (
                <h3>Loading Single Match</h3>
            ) : !singleMatchData || !singleMatchData ? (
                <h3>No matches</h3>
            ) : (
                <div>
                    <h3>{ singleMatchData.teams[ 0 ].team.name } { singleMatchData.teams[ 0 ].score } - { singleMatchData.teams[ 1 ].score } { singleMatchData.teams[ 1 ].team.name }</h3>
                </div>
            )}

            <h1>Matches:</h1>
            { isFetchingMatches ? (
                <h3>Loading Matches</h3>
            ) : !matchesData || !matchesData.length ? (
                <h3>No matches</h3>
            ) : (
                <div>
                    {matchesData.map((match) => {
                        return (
                            <h3>{ match.teams[ 0 ].team.name } { match.teams[ 0 ].score } - { match.teams[ 1 ].score } { match.teams[ 1 ].team.name }</h3>
                        );
                    })}
                </div>
            )}
        </div>
    );
}
