/* eslint-disable compat/compat */
import React, { Fragment } from 'react';
import { h, render } from 'preact';
import { useEffect, useRef, useState, useCallback } from 'preact/hooks';
import PropTypes from 'prop-types';
import {
    useGetLiveBlogQuery,
    useGetNewerThanQuery
} from 'common/store/modules/api/content-api';
import LiveBlogHeader from './live-blog-header/live-blog-header';
import LiveBlogEntries from './live-blog-entries/live-blog-entries';

const POLL_INTERVAL = 20000;
const ITEMS_TO_DISPLAY = 50;

/*

TODO: 
- Investigate better solution to view post/pinned post
- Tidy code
    - Remove unneeded hooks
    - Update JSDocs
- Ensure all widgets load correctly
*/

/**
 * LiveBlog
 *
 * This handles the logic and container rendering for each live blog component
 *
 * @param {string} blogData - The DOMElement data attributes
 *
 * @returns {JSX.Element} - Live Blog widgets
 */
export default function LiveBlog({ blogData = {} }) {
    const [keyEventPostData, setKeyEventPostData] = useState(null);
    const [currentPosition, setCurrentPosition] = useState(null);
    const [pagesToDisplay, setPagesToDisplay] = useState(1);
    const [blogPosts, setBlogPosts] = useState([]);
    const [blogPostsToDisplay, setBlogPostsToDisplay] = useState([]);
    const [blogOverview, setBlogOverview] = useState(null);
    const [author, setAuthor] = useState('');
    const [latestUpdateTime, setLatestUpdateTime] = useState(null);
    const loadMoreElement = useRef(null);
    const [moreToDisplay, setMoreToDisplay] = useState(true);
    const [blogId, setBlogId] = useState(blogData.blogId);
    const [observerTriggered, setObserverTriggered] = useState(false);
    const [isLive, setIsLive] = useState(false);

    const hasMultipleDays = blogData.multipleDays === 'true';
    const commentEmail = blogData.commentEmail;
    const defaultSubject = blogData.defaultSubject;
    const blogIds = blogData.blogIds.split(',');
    const blogDays = blogData.blogDays.split(',');
    const maxKeyEvents = Number(blogData.maxKeyEventsCount);

    const blogDaysArray = [];

    blogDays.forEach((day, index) => {
        blogDaysArray.push({
            day,
            id: blogIds[index]
        });
    });

    const resetBlog = useCallback(() => {
        setBlogPosts([]);
        setBlogPostsToDisplay([]);
        setCurrentPosition(null);
        setPagesToDisplay(1);
        setBlogOverview(null);
        setLatestUpdateTime(null);
    }, []);

    const { data: liveBlogData, isFetching } = useGetLiveBlogQuery({
        lang: 'en',
        id: blogId,
        params: {
            maxResults: ITEMS_TO_DISPLAY
        },
        belowPosition: currentPosition
    });

    const { data: newerPostsData } = useGetNewerThanQuery(
        {
            id: blogId,
            timestamp: latestUpdateTime,
            filterParams: { page: 0, pageSize: ITEMS_TO_DISPLAY }
        },
        { skip: !latestUpdateTime, pollingInterval: POLL_INTERVAL }
    );

    useEffect(() => {
        if (observerTriggered && blogPostsToDisplay.length) {
            setCurrentPosition(
                blogPostsToDisplay[blogPostsToDisplay.length - 1].position
            );
        }
    }, [observerTriggered]);

    useEffect(() => {
        // IntersectionObserver to
        const observer = new IntersectionObserver(
            (observedItems) => {
                [].slice.call(observedItems).forEach((observedItem) => {
                    if (observedItem.isIntersecting) {
                        setPagesToDisplay(
                            (prevPagesToDisplay) => prevPagesToDisplay + 1
                        );
                        setObserverTriggered(true);
                    }
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1
            }
        );

        if (loadMoreElement.current) {
            observer.observe(loadMoreElement.current);
        }
    }, []);

    useEffect(() => {
        const entries = liveBlogData?.overview
            ? liveBlogData.entries
            : liveBlogData;
        const newBlogs =
            !currentPosition || currentPosition > entries[0]?.position;
        if (entries?.length && newBlogs && !isFetching) {
            if (liveBlogData.overview) {
                setBlogOverview(liveBlogData.overview.header);
                setAuthor(liveBlogData?.overview?.author);
                setIsLive(
                    liveBlogData?.overview?.tags.find(
                        (tag) => tag.label === 'live-blog-is-live'
                    )
                        ? true
                        : false
                );
            } else if (liveBlogData.header) {
                setBlogOverview(liveBlogData.header);
            }

            setBlogPosts((prevBlogPosts) => [...prevBlogPosts, ...entries]);
            if (
                entries &&
                entries.length &&
                entries[entries.length - 1]?.position !== 0
            ) {
                if (entries[entries.length - 1]?.position) {
                    setMoreToDisplay(true);
                    setObserverTriggered(false);
                }
            }
        }
    }, [isFetching, liveBlogData]);

    useEffect(() => {
        const arrayItemsToDisplay = pagesToDisplay * ITEMS_TO_DISPLAY;
        const slicedArray = blogPosts.slice(0, arrayItemsToDisplay);

        if (arrayItemsToDisplay > blogPosts.length) {
            setMoreToDisplay(false);
        }

        const updateTimeArray = [...slicedArray]?.map((blog) => {
            return blog.updateTime;
        });
        const latestUpdateTime = updateTimeArray.length
            ? Math.max(...updateTimeArray)
            : null;

        setBlogPostsToDisplay(slicedArray);
        setLatestUpdateTime(latestUpdateTime);
    }, [blogPosts, pagesToDisplay]);

    useEffect(() => {
        if (newerPostsData?.overview) {
            setBlogOverview(newerPostsData.overview);
        } else if (newerPostsData?.header) {
            setBlogOverview(newerPostsData.header);
        }

        if (newerPostsData?.entries?.length) {
            setLatestUpdateTime(newerPostsData?.entries[0]?.updateTime);

            // Add isNewPost property to new posts
            const updatedNewerPosts = newerPostsData.entries.map((post) => ({
                ...post,
                isNewPost: true
            }));

            // Combine new posts with existing ones and remove duplicates
            const combinedPosts = [
                ...updatedNewerPosts,
                ...blogPostsToDisplay
            ].filter(
                (prevPosts, index, array) =>
                    array.findIndex((post) => post.id === prevPosts.id) ===
                    index
            );

            setBlogPostsToDisplay(combinedPosts);

            // Combine new posts with existing ones and remove duplicates
            const combinedBlogPosts = [
                ...updatedNewerPosts,
                ...blogPosts
            ].filter(
                (prevPosts, index, array) =>
                    array.findIndex((post) => post.id === prevPosts.id) ===
                    index
            );

            setBlogPosts(combinedBlogPosts);
        }
    }, [newerPostsData]);

    return (
        <div>
            <LiveBlogHeader
                liveBlogData={liveBlogData}
                blogPosts={blogPosts}
                blogOverview={blogOverview}
                setKeyEventPostData={setKeyEventPostData}
                hasMultipleDays={hasMultipleDays}
                blogDaysArray={blogDaysArray}
                blogId={blogId}
                setBlogId={setBlogId}
                resetBlog={resetBlog}
                commentEmail={commentEmail}
                defaultSubject={defaultSubject}
                isFetching={isFetching}
                maxKeyEvents={maxKeyEvents}
                author={author}
                isLive={isLive}
            />

            <LiveBlogEntries
                liveBlogData={liveBlogData}
                blogPostsToDisplay={blogPostsToDisplay}
                blogPosts={blogPosts}
                keyEventPostData={keyEventPostData}
                isFetching={isFetching}
            />

            <div ref={loadMoreElement} className="live-blog__observer">
                Observer
            </div>
        </div>
    );
}

LiveBlog.propTypes = {
    blogData: PropTypes.object
};
