import React from 'react';
import { h, render } from 'preact';
import { useEffect, useRef, useState, useCallback } from 'preact/hooks';
import PropTypes from 'prop-types';
import { useGetContentItemQuery } from 'common/store/modules/api/content-api';
import VideoScrubber from './controls/video-scrubber';
import PlayButton from './controls/play-button';
import VolumeButton from './controls/volume-button';
import ResolutionPicker from './controls/resolution-picker';
import CurrentTime from './controls/current-time';
import PictureInPicture from './controls/picture-in-picture';
import FullscreenButton from './controls/fullscreen-button';
import SVGIcon from './controls/svg-icon';
import Subtitles from './controls/subtitles';

/**
 *
 * @param root0
 * @param root0.playVideo
 * @param root0.setPlayer
 * @param root0.videoData
 * @param root0.player
 */
export default function VideoMediaKind( { playVideo, player, setPlayer, videoData = {} } ) {
    const playerContainer = useRef( null );
    const videoWrapper = useRef( null );
    const [ showMediaVideo, setShowMediaVideo ] = useState( false );
    const [ showControls, setShowControls ] = useState( false );
    const [ posterShowing, setPosterShowing ] = useState( true );
    const [ currentVideo, setCurrentVideo ] = useState( {} );
    const [ currentVideoId, setCurrentVideoId ] = useState( videoData?.pulseId );
    const [ isFullscreen, setIsFullscreen ] = useState( false );
    const [ previousVideoId, setPreviousVideoId ] = useState ( 0 );

    const showMeta = videoData.showMeta === "true";
    const autoplay = videoData.autoplay === "false" ? false : true;
    const { data: videoRequestData, isFetching} = useGetContentItemQuery(
        { type: 'video', id: currentVideoId }, 
        { 
            skip: !currentVideoId
        });

    useEffect( () => {
        if ( videoRequestData && videoRequestData.platform === 'MEDIA_KIND' && currentVideoId && !isFetching && (videoRequestData.mediaId !== previousVideoId) ) {
            setCurrentVideo({
                mediaId: videoRequestData.mediaId,
                title: videoRequestData.title,
                poster: videoRequestData.thumbnailUrl,
                id: videoRequestData.id,
                hls: videoRequestData?.additionalInfo?.mediaKindStreamingUrl || '',
                analytics: {
                    videoId: videoRequestData.mediaId
                }
            })
            setCurrentVideoId( videoRequestData.id );
        }

    }, [videoRequestData] )

    useEffect(  () => {
        if ( !playerContainer.current ) {
            return;
        }

        const playerConfig = {
            key: PULSE.app.environment.mediaKindKey,
            ui: false,
            log: {
                level: "info", // Other options are "info" | "error" | "warn" | "off"
            },
            playback: {
                muted: false,
                autoplay: autoplay
            },
            analytics: {
                key: PULSE.app.environment.mediaKindAnalyticsKey,
                title: videoData?.videoTitle
            }
        };
        
        const videoPlayer =  new mkplayer.MKPlayer(playerContainer.current, playerConfig);
        setPlayer( videoPlayer );

    }, [autoplay, playerContainer, setPlayer])

    useEffect( () => {
        if ( currentVideo?.mediaId && playerContainer.current && currentVideoId && (currentVideoId !== previousVideoId) ) {
            setShowMediaVideo( true )
            playVideo( currentVideo, playerContainer.current )
            setPreviousVideoId(currentVideoId)
        }
    }, [currentVideo, playVideo, previousVideoId])

    useEffect( () => {
        if ( player ) {
            player.on(mkplayer.MKPlayerEvent.Playing, () => {
                setPosterShowing( false );
            });
        }
    }, [ player ])

    useEffect( () => {
        document.body.addEventListener(
            PULSE.app.common.event.PLAYLIST.SET_MEDIA_MEDIA_KIND, ( event ) => {
                setCurrentVideoId( event.detail.video.trackerID || event.detail.video.id );
                const newUrl = PULSE.app.common.generateContentUrl( 'video', event.detail.video.trackerID || event.detail.video.id );
    
                history.pushState( {}, '', newUrl );
    
                document.querySelector('.js-react-video-player-mk').classList.remove('u-hide');
                document.querySelector('.js-brightcove-player').classList.add('u-hide')
            }
        );

        document.body.addEventListener(
            PULSE.app.common.event.PLAYLIST.UNLOAD_MEDIA_KIND_PLAYER, ( event ) => {
                setCurrentVideo( {} );
                setCurrentVideoId( 0 );
            }
        );
    }, [ ])

    const togglePlayPause =  useCallback( () => {
        setPosterShowing( false );

        if (player) {
            player.isPlaying() ? player.pause() : player.play();
        }
    }, [ player ] );

    return (
            <div onMouseOver={() =>  setShowControls( true ) } onMouseOut={() =>  setShowControls( false ) } className={ `video-player-mk js-media-kind-player ${ showMediaVideo ? '' : 'u-hide' } ${ showControls ? 'show-controls' : '' }  ${ posterShowing ? 'poster-showing' : '' }` }>
                <div className="video-player-mk__video-container" ref={ videoWrapper }>
                    { videoRequestData ? (
                        <div className="video-player-mk__video-title-container">
                                <h3 className="video-player-mk__video-title">{ videoRequestData?.title }</h3>    
                                <span className="video-player-mk__video-description">{ videoRequestData?.description }</span> 
                        </div>    
                    ) : null }
    
                    <div className="video-player-mk__poster-play-button" onClick={ () => togglePlayPause() }>
                        <SVGIcon name="play" cssClass="video-player-mk__poster-play-button-icon" />
                    </div>

                    <div 
                        ref={ playerContainer }
                        onClick={ () => togglePlayPause() }
                        id="MK_Video_Player"
                        className="video-player-mk__video js-video-player-mk"></div>

                    <div className="video-player-mk__controls-container">

                        <div className="video-player-mk__scrubber-container">
                            <VideoScrubber player={ player } />
                        </div>

                        <div className="video-player-mk__controls-wrapper video-player-mk__controls-wrapper--left">
                            <PlayButton player={ player } />

                            <VolumeButton player={ player } />

                            <CurrentTime player={ player } />
                        </div>
                        
                        <div className="video-player-mk__controls-wrapper video-player-mk__controls-wrapper--right">
                            <Subtitles player={ player } />

                            <ResolutionPicker player={ player } />

                            <PictureInPicture player={ player } playerContainer={ playerContainer } />

                            <FullscreenButton player={ player } playerContainer={ videoWrapper } setIsFullscreen={ setIsFullscreen } />
                        </div>
                    </div>
                </div>
                { videoRequestData && showMeta ? (
                    <div className="video-player__video-meta-wrapper">
                        <div className="video-player__info-wrapper">
                            <div className="video-player__meta-wrapper">
                                <div className="video-player__date-wrapper">
                                    <span className="video-player__date js-video-date">{ moment(videoRequestData.publishFrom).format('DD MMMM YYYY') }</span>
                                </div>
                            </div>
                            <h2 className="video-player__title js-video-title">{ videoRequestData.title }</h2>
                            <p className="video-player__description js-video-description">{ videoRequestData.description }</p>
                        </div>
                    </div>
                ) : null }
        </div>
    );
}

VideoMediaKind.propTypes = {
    playVideo: PropTypes.func.isRequired,
    player: PropTypes.object.isRequired,
    setPlayer: PropTypes.func.isRequired,
    videoData: PropTypes.object
};