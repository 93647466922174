import React from 'react';
import { h, render } from 'preact';
import { Provider } from 'react-redux';
import LiveBlog from '../components/live-blog';
import PropTypes from 'prop-types';

export const LiveBlogContainer = ( { blogData } ) => {

    return (
        <LiveBlog
            blogData={ blogData }
        />
    );
};

LiveBlogContainer.propTypes = {
    playVideo: PropTypes.func.isRequired,
    player: PropTypes.object.isRequired,
    setPlayer: PropTypes.func.isRequired,
    blogData: PropTypes.object
};