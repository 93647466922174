import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { TestReactContainer } from '../components/TestReact';

export const DefaultTestReact = ({ store }) => {
    return (
        <Provider store={store}>
            <TestReactContainer />
        </Provider>
    );
};

DefaultTestReact.propTypes = {
    store: PropTypes.object.isRequired
};
