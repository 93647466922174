import React from 'react';
import { h, render } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import PropTypes from 'prop-types';
import SVGIcon from './svg-icon';

/**
 *
 * @param root0
 * @param root0.player
 */
export default function ResolutionPicker( { player } ) {
    const [ videoQualities, setVideoQualities ] = useState( [] );
    const [ currentQuality, setCurrentQuality ] = useState( 'auto' );

    useEffect( () => {
        if ( player ) {
            player.on(mkplayer.MKPlayerEvent.Ready, () => {
                let tempQualities = [];
                let qualities = [];
                //Removing multiple qualities of same width/height
                player.getAvailableVideoQualities().reverse().forEach( quality => {
                    if ( !tempQualities.includes( quality.height ) ) {
                        qualities.push( quality )
                        tempQualities.push( quality.height )
                    }
                } );

                setVideoQualities( qualities );
            });
        }
    }, [ player ] )

    const updateQuality =  useCallback( ( quality ) => {
        player.setVideoQuality( quality );
        setCurrentQuality( quality );
    }, [ player ] );

    return (
        <div className="video-player-mk__control video-player-mk__control--quality">
            <span className="video-player-mk__control-icon video-player-mk__control-icon--resolution-picker">
                <SVGIcon name="quality-selector" cssClass="video-player-mk__control-button-icon" />
            </span>
            <div className="video-player-mk__quality-list">
                {
                    videoQualities.map( ( quality, index ) => {
                        return (
                            <button key={ index } onClick={ () => updateQuality( quality.id ) } className={ `video-player-mk__quality-list-button ${ currentQuality === quality.id ? 'is-active' : '' }` } type="button" title="Quality Control" aria-disabled="false">
                                { quality.height }p
                            </button>
                        )
                    })
                }
                <button key="auto" onClick={ () => updateQuality( 'auto' ) } className={ `video-player-mk__quality-list-button ${ currentQuality === 'auto' ? 'is-active' : '' }` } type="button" title="Play" aria-disabled="false">
                    Auto
                </button>
            </div>
        </div>
    );
}

ResolutionPicker.propTypes = {
    player: PropTypes.object.isRequired
};